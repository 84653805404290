/* Solid backgrounds are a better fit for MAPSentry */

.iziToast.iziToast-color-white {
  background: #F1F1F1;
  border-color: #F1F1F1;
}
.iziToast.iziToast-color-orange {
  background: rgba(255,207,165,1);
  border-color: rgba(255,207,165,1);
}
.iziToast.iziToast-color-yellow {
  background: rgba(255,249,178,1);
  border-color: rgba(255,249,178,1);
}
.iziToast.iziToast-color-blue {
  background: rgba(157,222,255,1);
  border-color: rgba(157,222,255,1);
}
.iziToast.iziToast-color-green {
  background: rgba(166,239,184,1);
  border-color: rgba(166,239,184,1);
}

/* Stronger red for errors */
.iziToast.iziToast-color-red {
    background: #e7505a;
    border-color: #e7505a;
}

/* No box shadow, please. */
.iziToast:after {
    box-shadow: none;
}
